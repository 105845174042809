import React from "react";
import styled from "styled-components";

import fiveSixFour from "../static/assets/564.png";
import { TextContainer, ImageContainer } from "./styles";

const StoryContainer = styled.div`
  display: flex;
  margin: 10rem 5rem;

  hr {
    background-color: #e02b20;
    height: 1px;
    border: none;
  }

  @media (max-width: 1030px) {
    display: block;
    margin: 10rem 0.5rem;
  }
`;

function Story() {
  return (
    <StoryContainer>
      <ImageContainer>
        <img src={fiveSixFour} alt="Our Story" />
      </ImageContainer>
      <TextContainer>
        <h2>Our Story</h2>
        <hr />
        <p>
          Connoisseurship as a discipline has been continually on the decline
          across the world since at least the 1960's. The “financialization of
          everything” effect which has taken its place as a mindset has devalued
          the world's unit of account (the USD), de-prioritized creativity, and
          stifled critical thinking to the tune of the latest Tik Tok viral
          video. However, with the advent of blockchain and distributed ledger
          technology, a frictionless path has emerged by which to approach the
          disparity between good ideas and bad ideas in the landscape of merit.
          The unwinding of this road has begun to prove how important individual
          taste is to a healthy and gestalt-centered culture and is fueled by
          the promise of revolution that NFT's (Non Fungible Tokens) have
          ignited with the uninitiated. At Marque Media, we stand for opening
          the eyes of the masses to the passion that lies dormant under years of
          cultural stifling, such that ones' own “taste” in their personal
          participation for creating a new way forward is fostered, encouraged,
          and able to be met with the requisite feedback that gives their life
          value and meaning. We do this by connecting our constituents at the
          nexus of inspiration, ingenuity, knowledge, and exploration to create
          a cohesive approach to capturing value and allowing those who have the
          most value to give - artists and creators - a reliable and effective
          way to monetize and spread their influence far and wide, without the
          dependency on traditionally limiting avenues which artificially
          cheapen the work.
        </p>
      </TextContainer>
    </StoryContainer>
  );
}

export default Story;
