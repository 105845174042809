import React from 'react';
import Layout from '../components/layout/Layout';
import styled from 'styled-components';

const CardLayout = styled.div``;

const StyledCard = styled.div`
  height: 600px;
  width: 500px;
`;

const videos = [
  <iframe
    src="https://player.thetavideoapi.com/video/video_1yr916npbc11ndnpqg55p1hjw5?autoplay=0"
    border="0"
    width="100%"
    height="100%"
    allowfullscreen
  />
];

function VideoApi() {
  return (
    <Layout>
      <CardLayout>
        {videos.map(video => {
          return (
            <StyledCard>
              {video}
            </StyledCard>
          )
        })}
      </CardLayout>
    </Layout>
  );
}

export default VideoApi;
