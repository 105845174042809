import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./routes/Home";
import Collection from "./routes/Collection";
import Store from "./routes/Store";
import Challenges from "./routes/Challenges";
import Login from "./routes/Login";
import VideoApi from "./routes/VideoApi";
import RequireAuth from "./components/RequireAuth";
import Discord from "./routes/Discord";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Marque Media</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Kanit:wght@600&family=Roboto:wght@500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/store"
            element={
              <RequireAuth>
                <Store />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/discord"
            element={
              <RequireAuth>
                <Discord />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/challenges"
            element={
              <RequireAuth>
                <Challenges />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/collection"
            element={
              <RequireAuth>
                <Collection />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="video-api"
            element={
              <RequireAuth>
                <VideoApi />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
