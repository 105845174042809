import React from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";

const StyledDiscord = styled.div`
  text-align: center;
`;

function Discord() {
  return (
    <Layout>
      <StyledDiscord>
        <p>Discord</p>
        <iframe
          src="https://discord.com/widget?id=802980969674047509&theme=dark"
          width="350"
          height="500"
          allowtransparency="true"
          frameborder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </StyledDiscord>
    </Layout>
  );
}

export default Discord;
