import React from "react";
import styled from "styled-components";

import WinstonImage from "../static/assets/winston_text.png";
import BenImage from "../static/assets/ben_text.png";
import TrevorImage from "../static/assets/trevor_2_text.png";

const TeamContainer = styled.div`
  margin: 3rem 5rem;

  #title {
    font-weight: 400;
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
  }

  hr {
    width: 72px;
    margin-top: 0.5rem;
    background-color: #e02b20;
    height: 1px;
    border: none;
  }

  @media (max-width: 1030px) {
    display: block;
    margin: 3rem 0.25rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;

  @media (max-width: 1030px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  inline-size: 368px;
  overflow-wrap: break-word;

  li {
    list-style: none;
  }

  .experience {
    text-align: left;
  }
`;

function Team() {
  return (
    <TeamContainer>
      <h2 id="title">Our Team</h2>
      <hr />
      <ImageContainer>
        <Card>
          <img src={WinstonImage} alt="Winston Goodfellow" />
          <div className="experience">
            <ul>
              <li>
                - Award-winning automotive writer and photographer (60
                publications & 15 books)
              </li>
              <li>
                - Chief Class Judge and/or Class Judge at the world's top
                concours
              </li>
              <li>
                - Advisor, broke and guidance expert to numerous
                investors/collectors in the auto space
              </li>
            </ul>
          </div>
        </Card>
        <Card>
          <img src={TrevorImage} alt="Trevor Snowden" />
          <div className="experience">
            <ul>
              <li>- 30 year game development veteran</li>
              <li>
                - Numerous number one selling games for leading publishers
              </li>
              <li>
                - Professional race car driver and racing simulation enthusiast
              </li>
              <li style={{ opacity: 0 }}> - Placeholder</li>
            </ul>
          </div>
        </Card>
        <Card>
          <img src={BenImage} alt="Ben Wilkening" />
          <div className="experience">
            <ul>
              <li>
                - Early adopter of crypto financial frameworks and contract
                automation protocols
              </li>
              <li>- Web 3.0 incentive and gamification specialist</li>
              <li>
                - 10 years experience consulting with corporations on ERP &
                legal compliance technologies (incl DLT)
              </li>
            </ul>
          </div>
        </Card>
      </ImageContainer>
    </TeamContainer>
  );
}

export default Team;
