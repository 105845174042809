import React from "react";
import styled from "styled-components";
import MarketplaceButton from "../components/MarketplaceButton";

const StyledLanding = styled.div`
  margin-bottom: 20rem;

  h2 {
    font-size: 6rem;
    font-weight: 400;
    margin-top: 12rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 1030px) {
    h2 {
      font-size: 4.5rem;
      margin-top: 6rem;
    }
  }

  @media (max-width: 769px) {
    h2 {
      font-size: 3rem;
    }
  }
`;

const TextContainer = styled.div`
  margin: 0;
  padding: 0;
`;

function Landing() {
  return (
    <StyledLanding>
      {/* <img
            src={flagUpper}
            alt="Racing Flag"
            style={{ width: "20rem", position: "static", top: 0, left: 0 }}
          /> */}

      <h2>CHALLENGES & PRIZES</h2>
      <div>
        <p style={{ margin: 0, padding: 0 }}>Marque Media NFT Challenges</p>
        <p style={{ margin: 0, padding: 0 }}>
          <strong>Collect-to-earn exclusive prizes</strong>
        </p>
        <p style={{ marginBottom: "2rem", padding: 0 }}>
          <strong>SOLD OUT</strong>
        </p>
        <MarketplaceButton />
      </div>
    </StyledLanding>
  );
}

export default Landing;
