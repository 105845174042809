import React from "react";
import styled from "styled-components";
import { ReactComponent as ForwardIcon } from "../static/assets/forward-icon.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;

  p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Button = styled.button`
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  background: #ea0001;
  border: none;
  color: #f5eded;
  padding: 1rem;

  :hover {
    cursor: pointer;
    filter: brightness(85%);
  }
`;
function MarketplaceButton() {
  return (
    <form action="https://goodfellow.thetadrop.com/marketplace?content_creator=user_mdfrc64pqrq6fhjrb34bragtrmm">
      <Button type="submit">
        <Container>
          <p>VIEW IN MARKETPLACE</p>
          <ForwardIcon />
        </Container>
      </Button>
    </form>
  );
}

export default MarketplaceButton;
