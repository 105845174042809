import { Navigate } from "react-router-dom";
import useAuthContext from "../auth/useAuthContext";

function RequireAuth({ children }) {
  const { user } = useAuthContext();

  return user?.isOwner === true ? children : <Navigate to="/" replace />;
}

export default RequireAuth;
