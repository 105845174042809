import React from "react";
import styled from "styled-components";

import garageImage from "../static/assets/THETA_OFFLINE_Screen_1.jpg";
import logo from "../static/assets/Marque_Media_Logo_V_Badge.png";
import YoutubeIcon from "../static/assets/youtube.svg";
import TwitterIcon from "../static/assets/twitter.svg";
import UnsplashIcon from "../static/assets/unsplash.svg";
import WebsiteIcon from "../static/assets/website-icon.svg";
import MediumIcon from "../static/assets/medium.svg";

import MarketplaceButton from "../components/MarketplaceButton";

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-transform: uppercase;
  }

  img {
    width: 15rem;
    margin: 1rem 0;
  }

  .garage {
    position: relative;

    img {
      width: 40rem;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .socials img {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 1030px) {
    .garage img {
      width: 30rem;
    }
  }

  @media (max-width: 769px) {
    a {
      font-size: 0.75rem;
    }

    img {
      width: 12rem;
    }

    .garage img {
      width: 22rem;
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <h2>Fill Your Garage!</h2>
      <div className="garage">
        <img src={garageImage} id="garageImage" alt="Garage" />
        <div className="centered">
          <MarketplaceButton />
        </div>
      </div>
      <img src={logo} alt="Marque Media Logo" />
      <div className="socials">
        <a href="https://www.youtube.com/channel/UCFkjpU7HOOt0xXu7Pe_nlJQ">
          <img src={YoutubeIcon} alt="Youtube Link" />
        </a>
        <a href="https://twitter.com/TheWGoodfellow">
          <img src={TwitterIcon} alt="Twitter Link" />
        </a>
        <a href="https://unsplash.com/@winston_goodfellow">
          <img src={UnsplashIcon} alt="Unsplash Link" />
        </a>
        <a href="https://winstongoodfellow.com">
          <img src={WebsiteIcon} alt="Personal Website Link" />
        </a>
        <a href="https://medium.com/@winstongoodfellow">
          <img src={MediumIcon} alt="Medium Link" />
        </a>
      </div>
    </StyledFooter>
  );
}

export default Footer;
