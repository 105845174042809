import React from "react";
import wheelImage from "../../static/assets/Scroll_Wheel_Asset.png";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 242px;
  margin: 1rem auto;

  hr {
    width: 80px;
    background-color: #292a2e;
    height: 1px;
    border: none;
  }
`;

function Separator() {
  return (
    <Container>
      <hr />
      <img src={wheelImage} />
      <hr />
    </Container>
  );
}

export default Separator;
