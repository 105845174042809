import React from "react";
import Layout from "../components/layout/Layout";

function Store() {
  return (
    <Layout>
      <div style={{ textAlign: "center" }}>
        <p>Store</p>
      </div>
    </Layout>
  );
}

export default Store;
