import React, { useState } from "react";
import { ThetaDropConnect } from "@thetalabs/theta-drop-connect";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MarketplaceButton from "../components/MarketplaceButton";
import logo from "../static/assets/Marque_Media_Logo_V_Badge.png";
import retroRidesLogo from "../static/assets/Retro_Rides_Logo_corrected.png";
import thetaPass from "../static/assets/Theta_Pass.png";
import lowerRacingFlag from "../static/assets/Flag_Lower.png";
import upperRacingFlag from "../static/assets/Flag_Upper.png";
import useAuthContext from "../auth/useAuthContext";

const LoginContainer = styled.div`
  text-align: center;

  .upper-racing-flag {
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 300px;
    }
  }

  .lower-racing-flag {
    height: 144px;
    position: absolute;
    right: 0;

    img {
      width: 300px;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  img {
    width: 18rem;
    padding: 2rem;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    margin-top: 5rem;

    img {
      width: 10rem;
      padding: 1rem;
    }
  }
`;

const InformationContainer = styled.div`
  margin: 0 10rem;
  color: #ea0001;

  @media (max-width: 769px) {
    margin: 2rem;
  }
`;

const DisclaimerContainer = styled.div`
  margin: 0 20rem;
  color: #6e6e6e;

  @media (max-width: 769px) {
    margin: 3rem;
  }
`;

const Button = styled.button`
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  background: #ea0001;
  border: none;
  color: #f5eded;
  padding: 1rem;
  :active {
    filter: brightness(85%);
  }
  :hover {
    cursor: pointer;
    filter: brightness(85%);
  }
`;

function Login() {
  const navigate = useNavigate();
  const { changeUser } = useAuthContext();
  const [isOwner, setIsOwner] = useState(false);
  const [tpsId, setTpsId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [localUserData, setLocalUserData] = useState(null);

  const AppId = "dapp_jk2r4wrs0fzp33ui4c9c5yptf3k";
  let redirectURL = "";
  if (process.env.NODE_ENV === "development") {
    redirectURL = "http://localhost:3000/";
  } else {
    redirectURL =
      "https://marque-media.com/thetadrop-auth-finished.html";
  }

  const WinstonGoodfellowId = "user_mdfrc64pqrq6fhjrb34bragtrmm";

  const thetaDrop = new ThetaDropConnect();

  const refreshUser = async () => {
    const filters = {
      content_creator_id: WinstonGoodfellowId,
    };
    const nfts = await thetaDrop.fetchUserNFTs(filters);

    const newUserData = await thetaDrop.fetchUser();
    const currentIsOwner = await thetaDrop.checkUserIsOwner(filters);

    const mergedUserData = {
      ...newUserData,
      collection: nfts,
      isOwner: currentIsOwner,
    };
    changeUser(mergedUserData);

    if (currentIsOwner) {
      navigate("/collection");
    }
  };

  // const finishConnectViaRedirect = async () => {
  //   const result = await thetaDrop.finishConnectViaRedirect();
  //   if (result) {
  //     const { snsId, oauth2Token } = result;

  //     setTpsId(snsId);
  //     setAuthToken(oauth2Token);

  //     refreshUser();
  //   }
  // };

  // useEffect(() => {
  //   finishConnectViaRedirect();
  // });

  const connectToThetaDrop = async () => {
    const { snsId, oauth2Token } = await thetaDrop.connectViaPopup(
      AppId,
      redirectURL
    );

    setTpsId(snsId);
    setAuthToken(oauth2Token);

    refreshUser();
  };

  // const connectToThetaDropViaRedirect = async () => {
  //   let hostUri = "";
  //   if (process.env.NODE_ENV === "development") {
  //     hostUri = "http://localhost:3000";
  //   } else {
  //     hostUri = "https://winston-goodfellow-thetadrop.netlify.app";
  //   }
  //   console.log("hostUri: " + hostUri);

  //   thetaDrop.connectViaRedirect(AppId, hostUri);
  // };

  return (
    <LoginContainer>
      <div className="upper-racing-flag">
        <img src={upperRacingFlag} alt="Racing Flag" />
      </div>
      <ImageContainer>
        <img src={logo} alt="Marque Media Logo" />
        <img src={retroRidesLogo} alt="Retro Riders Logo" />
      </ImageContainer>
      <InformationContainer>
        <p>
          A 'Retro Rides' NFT grants you access to the Marque Media Garage - a
          community where you will gain access to exclusive automotive
          merchandise, video content you won't find anywhere else, a members
          only Discord server - where you will have direct access to Window
          Goodfellow and the Marque Media team - and many more benefits being
          added soon.
        </p>
      </InformationContainer>
      <br />
      <Button onClick={connectToThetaDrop}>PROVE OWNERSHIP</Button>
      <br />
      <DisclaimerContainer>
        <p>
          Only Retro Rides in your collection which are from OPENED packs (and
          NOT currently listed for sale on the ThetaDrop marketplace) qualify to
          be used as proof of ownership.
        </p>
      </DisclaimerContainer>
      <MarketplaceButton />
      <br />
      <img
        src={thetaPass}
        style={{ width: 100, marginTop: "5rem" }}
        alt="Powered by Theta Pass"
      />

      {/* <button onClick={connectToThetaDropViaRedirect}>
              Login to ThetaDrop via Redirect
            </button> */}
      <div className="lower-racing-flag">
        <img src={lowerRacingFlag} alt="Racing Flag" id="lower-racing-flag" />
      </div>
    </LoginContainer>
  );
}

export default Login;
