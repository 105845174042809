import React from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import useAuthContext from "../auth/useAuthContext";
import Separator from "../components/layout/Separator";

const Container = styled.div`
  text-align: center;
  color: #6e6e6e;

  h3 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #ea0001;
  }

  .sub-collection {
    margin: 2.5rem 6rem;
  }

  @media (max-width: 1030px) {
    .sub-collection {
      margin: 2.5rem 3rem;
    }
  }

  @media (max-width: 769px) {
    .sub-collection {
      margin: 2.5rem 2rem;
    }
  }
`;

function Collection() {
  const { user } = useAuthContext();
  console.log("Collection");
  console.log(user.collection);
  return (
    <Layout>
      <Container>
        <div className="sub-collection">
          <h3>BADGES</h3>
          <p>No Badges Owned</p>
        </div>
        <Separator />
        <div className="sub-collection">
          <h3>RETRO RIDES</h3>
          {user.collection?.types.map((item) => {
            return (
              <div>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ width: "10rem" }}
                />
                <p>
                  {item.metadata.rarity &&
                    item.metadata.rarity.toUpperCase() + "-"}
                  {item.metadata.make} {item.metadata.model}
                </p>
              </div>
            );
          })}
        </div>
        <Separator />
        <div className="sub-collection">
          <h3>UNOPENED PACKS</h3>
          {user.collection.packs ? (
            user.collection.packs.map((item) => {
              return (
                <div>
                  <img
                    src={item.image_url}
                    style={{ width: "10rem" }}
                    alt={item.title}
                  />
                  <p>{item.title}</p>
                </div>
              );
            })
          ) : (
            <p>No Unopened Packs</p>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default Collection;
