import React from "react";
import styled from "styled-components";

import PitchDeck from "../static/Marque-Media_Pitch-Deck_v1.06.pdf";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0;
  padding: 0;

  h2 {
    font-weight: 400;
    font-size: 3rem;
    margin: 0;
    padding: 0;
    margin-left: 3rem;
  }

  hr {
    width: 72px;
    background-color: #e02b20;
    margin: 0;
    margin-left: 3rem;
    margin-top: 0.5rem;
    height: 1px;
    border: none;
  }

  ul {
    margin: 0;
    margin-left: 3rem;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1030px) {
    margin: 8rem 2rem;
  }
`;

const StyledRoadMap = styled.div``;

const RoadMapPhase = styled.div`
  height: 200px;
  border-left: 1px solid #31363c;

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-left: 3rem;
    margin-bottom: 0.75rem;
  }

  @media (max-width: 1030px) {
    height: 264px;
  }
`;

const RoadMapDot = styled.span`
  height: 12px;
  width: 12px;
  background-color: #e02b20;
  border-radius: 50%;
  display: inline-block;
  transform: translate(-6px, 12px);
`;

function RoadMap() {
  return (
    <Container>
      <div>
        <h2>Roadmap</h2>
        <hr />
        <StyledRoadMap>
          <RoadMapPhase>
            <RoadMapDot />
            <h3>PHASE 1</h3>
            <ul>
              <li>- Theta Drop - Oct 2021</li>
              <li>- First Air Drops - Nov 2021</li>
              <li>- Podcast Launch - Dec 2021</li>
              <li>- Dev work on Project 'AutoSpa' begins Jan 2022</li>
              <li>
                - Funding for Project 'AutoSpa' opens 2022,{" "}
                <a href={PitchDeck} target="_blank" style={{ margin: 0 }}>
                  click here
                </a>{" "}
                to download pitch deck
              </li>
            </ul>
          </RoadMapPhase>
          <RoadMapPhase>
            <RoadMapDot />
            <h3>PHASE 2</h3>
            <ul>
              <li>- Marque Media Website Launch- Sept 2022</li>
              <li>- Theta Drop Challenge Kickoff - Sept 2022</li>
              <li>- Discord Server for NFT holders unlocks - Oct 2022</li>
              <li>- Podcast Continuation - Nov 2022</li>
              <li>- Marque Media Merch - Nov 2022</li>
            </ul>
          </RoadMapPhase>
          <RoadMapPhase>
            <RoadMapDot />
            <h3>PHASE 3</h3>
            <ul>
              <li>- Theta Video API Exclusive Content - Q1 2023</li>
              <li>- Unique content and giveaway contests - Q1 2023</li>
              <li>- Major Manufacturer Partnership reveal - Late 2023</li>
            </ul>
          </RoadMapPhase>
          <RoadMapPhase>
            <RoadMapDot />
            <h3>PHASE 4</h3>
            <ul>
              <li>- More Updates COMING SOON!</li>
            </ul>
          </RoadMapPhase>
        </StyledRoadMap>
      </div>
    </Container>
  );
}

export default RoadMap;
