import React from "react";
import styled from "styled-components";

import retroOneEpic from "../static/assets/retro_1_EPIC.jpg";
import retroFourMythic from "../static/assets/retro_4_MYTHIC.jpg";
import retroRidesLogo from "../static/assets/Retro_Rides_Logo_corrected.png";

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  img {
    width: 24rem;
  }

  @media (max-width: 1030px) {
    display: block;

    img {
      width: 12rem;
      padding: 0.5rem;
    }
  }

  @media (max-width: 769px) {
    img {
      padding: 0.2rem;
    }
  }
`;

function Images() {
  return (
    <ImagesContainer>
      <img src={retroFourMythic} alt="Retro 4 Mythic" />
      <img
        src={retroRidesLogo}
        alt="Retro Riders Logo"
        style={{ margin: "0 2rem" }}
      />
      <img src={retroOneEpic} alt="Retro 1 Epic" />
    </ImagesContainer>
  );
}

export default Images;