import Footer from "./Footer";
import Navbar from "./Navbar";

function Layout({ children }) {
  return (
    <main>
      <Navbar />
      <div style={{ padding: "2rem" }}>{children}</div>
      <Footer />
    </main>
  );
}

export default Layout;
