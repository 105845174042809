import React from "react";
import styled from "styled-components";

import retroPack from "../static/assets/retro_pack.jpg";
import { TextContainer, ImageContainer } from "./styles";

const ProjectContainer = styled.div`
  display: flex;
  margin: 10rem 5rem;

  hr {
    background-color: #e02b20;
    height: 1px;
    border: none;
  }

  @media (max-width: 1030px) {
    display: block;
    margin: 10rem 0.5rem;
  }
`;

function Project() {
  return (
    <ProjectContainer>
      <ImageContainer>
        <img src={retroPack} alt="Retro Pack" />
      </ImageContainer>
      <TextContainer>
        <h2>The Project</h2>
        <hr />
        <p>
          Winston Goodfellow is an award-winning automotive writer and
          photographer whose work has appeared in more than 60 magazines in 12
          countries. He's authored six books and seven monographs, been a judge
          at the worldss best car shows such as Pebble Beach, Villa d'Este and
          Amelia Island. Since most can't afford to wrap their fingers around
          the wheel of an automotive icon, Winston Goodfellow and Marque Media
          are unwrapping examples of automotive legend directly into your
          fingers instead. This set of 'Retro Rides' includes the likes of a
          world champion, a true “one off” enigma, Winston's favorite modern
          supercar, and other truly rare and kick ass stuff! Fill your virtual
          garage with some of the world's greatest driving experiences for a
          fraction of what it would cost in the real world. Burn that TFuel, and
          drive away with a true piece of digital automotive history!
        </p>
      </TextContainer>
    </ProjectContainer>
  );
}

export default Project;
