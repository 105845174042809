import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import logoWithTitle from "../static/assets/Marque_Media_TITLE_WHITE.png";

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  background: #16171b;
  padding-bottom: 1rem;
  z-index: 1000;
  transition: all 2s ease-out;

  @media (max-width: 1030px) {
    a {
      font-size: 1rem;
    }
  }

  @media (max-width: 769px) {
    a {
      font-size: 0.75rem;
    }
  }
`;

const MembersLink = styled(Link)`
  color: #e02b20;
`;

function Header({ shouldShrink }) {
  return (
    <StyledHeader>
      <img
        src={logoWithTitle}
        alt="Marque Media Logo"
        style={{
          width: shouldShrink ? "10rem" : "20rem",
          transition: "all 0.3s ease-in-out",
        }}
      />
      <nav>
        <a href="#home">Home</a>
        <a href="#project">Project</a>
        <a href="#roadmap">Roadmap</a>
        <a href="#story">Story</a>
        <a href="#team">Team</a>
        <MembersLink to="/login" className="members-link">
          Members
        </MembersLink>
      </nav>
    </StyledHeader>
  );
}

export default Header;
