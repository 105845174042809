import styled from "styled-components";

const ImageContainer = styled.div`
  img {
    width: 20rem;
  }

  @media (max-width: 1030px) {
    img {
      width: 18rem;
    }
  }

  @media (max-width: 769px) {
    img {
      width: 22rem;
    }
  }
`;

const TextContainer = styled.div`
  text-align: left;
  margin-left: 4rem;
  margin-right: 10rem;

  h2 {
    margin: 0;
    padding: 0;
  }

  hr {
    width: 48px;
    color: #e02b20;
    margin: 0;
    margin-top: 0.5rem;
  }

  @media (max-width: 1030px) {
    margin-top: 1rem;
    margin-left: 6rem;
    margin-right: 6rem;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export { TextContainer, ImageContainer };
