import React from "react";
import Layout from "../components/layout/Layout";

function Challenges() {
  return (
    <Layout>
      <div style={{ textAlign: 'center' }}>
        <p>Challenges</p>
      </div>
    </Layout>
  );
}

export default Challenges;
