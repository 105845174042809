import React, { useEffect, useState } from "react";

import "./Home.css";

import Separator from "../components/layout/Separator";
import Header from "../landing/Header";
import Landing from "../landing/Landing";
import Images from "../landing/Images";
import Project from "../landing/Project";
import RoadMap from "../landing/RoadMap";
import Story from "../landing/Story";
import Team from "../landing/Team";
import Footer from "../landing/Footer";

function Home() {
  const [shouldShrink, setShouldShrink] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShouldShrink((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop >= 235 ||
            document.documentElement.scrollTop >= 235)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 5 &&
          document.documentElement.scrollTop < 5
        ) {
          return false;
        }

        return isShrunk;
      });
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <p id="home"></p>
      <Header shouldShrink={shouldShrink} />
      <main>
        <Landing />
        <Images />
        <div id="project"></div>
        <Separator />
        <Project />
        <div id="roadmap"></div>
        <Separator />
        <RoadMap />
        <div id="story"></div>
        <Separator />
        <Story />
        <div id="team"></div>
        <Separator />
        <Team />
        <Separator />
        <Footer />
      </main>
    </div>
  );
}

export default Home;
