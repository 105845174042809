import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import logo from "../../static/assets/Marque_Media_Logo_V_Badge_bw.png";
import useAuthContext from "../../auth/useAuthContext";
import "./Navbar.css";

const StyledHeader = styled.header`
  textalign: center;

  .logo {
    width: 1rem;
  }

  .grid-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    nav {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      text-align: center;

      a {
        margin: 4px;
      }
    }

    .grid-container {
      align-items: flex-start;
    }

    #username {
      display: none;
    }

    #avatar-container {
      padding: 1rem 0.5rem;
    }
  }
`;

function Navbar() {
  const { user } = useAuthContext();
  return (
    <StyledHeader>
      {user && user.isOwner ? (
        <div className="grid-container">
          <div style={{ padding: "0.5rem" }}>
            <img src={logo} alt="Marque Media Logo" style={{ width: "4rem" }} />
          </div>
          <nav>
            <Link to="/collection">Collection</Link>
            <Link to="/store">Shop</Link>
            <a href="https://goodfellow.thetadrop.com/marketplace?content_creator=user_mdfrc64pqrq6fhjrb34bragtrmm">
              Marketplace
            </a>
            <Link to="/discord">Discord</Link>
            <Link to="/video-api">Video API (In Progress)</Link>
          </nav>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            id="avatar-container"
          >
            <p style={{ marginRight: "0.25rem" }} id="username">
              {user.username}
            </p>
            {user.avatar_url && (
              <img
                src={user.avatar_url}
                alt="Avatar"
                style={{
                  filter: "grayscale(100%)",
                  height: 48,
                  width: 48,
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        </div>
      ) : null}
    </StyledHeader>
  );
}

export default Navbar;
