import { useState } from "react";
import AuthContext from "./AuthContext";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isOwner, setIsOwner] = useState(null);

  const changeUser = (user) => {
    setUser(user);
  };

  const setOwnership = (owner) => {
    setIsOwner(owner);
  };

  return (
    <AuthContext.Provider value={{ user, changeUser, isOwner, setOwnership }}>
      {children}
    </AuthContext.Provider>
  );
};
