import thetaPass from "../../static/assets/Theta_Pass.png";

function Footer() {
  return (
    <div style={{ textAlign: "center", padding: "1rem" }}>
      <img src={thetaPass} style={{ width: 100 }} alt="Twitter Link"/>
    </div>
  );
}

export default Footer;
